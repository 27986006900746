import { render, staticRenderFns } from "./resale.vue?vue&type=template&id=c16215ba&scoped=true&"
import script from "./resale.vue?vue&type=script&lang=js&"
export * from "./resale.vue?vue&type=script&lang=js&"
import style0 from "./resale.vue?vue&type=style&index=0&id=c16215ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c16215ba",
  null
  
)

export default component.exports